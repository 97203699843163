import ng from 'angular';
import { OrderItemDisplayLine } from '@/components/setup/setup';

export class TemplateSetupJobPanelController {
    public static $inject: string[] = []
    constructor(){}

    orderItemDisplayLines: (lines: OrderItemDisplayLine[]) => OrderItemDisplayLine[] = (input) => [];
}

export class TemplateSetupJobPanelComponent implements ng.IComponentOptions {
    public bindings = {
        orderItemDisplayLines: '<',
    };
    public template = require('./job-panel.html');
    public controller = TemplateSetupJobPanelController;
}
