import * as Types from '@/types';
import {
    GenericSetup,
    OrderItemDisplayLine,
    OrderItemStatus,
    GenericInjectedRequests,
    MaybeLoading,
} from '@/components/setup/setup';

type OrchestrationJob = Types.ViewTypes.OrchestrationJob;
type Domain = Types.DomainApi.Domain;
type APISingleResponse<T> = Types.UI.APISingleResponse<T>;

export type DomainAdditionalInjectedRequests = {
    loadDomain: (id: string) => Promise<Domain>,
    updateDomain: (domain: Domain) => Promise<APISingleResponse<Domain>>,
}

export type InjectedRequests = GenericInjectedRequests & DomainAdditionalInjectedRequests;

export class DomainSetup extends GenericSetup {

    // fetched async from API
    private item: MaybeLoading<Domain> = {state: 'waiting'};

    constructor(
        protected requests: InjectedRequests,
        private applyWrapper?: (fn: () => void) => void,
    ) {
        super();
        applyWrapper ??= (fn: () => void) => fn();
    }

    protected jobIsDone = (job: OrchestrationJob) => {
        switch (job.objectType) {
            case 'domain':
                if (this.item.state == 'waiting') {
                    this.item = {state: 'loading'};
                    this.requests.loadDomain(job.objectId).then(
                        (domain: Domain) => {
                            this.item = { state: 'loaded', data: domain };
                        },
                    );
                }
                break;
        }

    }

    protected postprocessOrderItemDisplayLines = () => {}

    protected orderItemDisplayLinesBeforeJobIsLoaded = (): OrderItemDisplayLine[] => {
        // Loading data, to avoid jumping element positions
        const lines: OrderItemDisplayLine[] = [];
        lines.push(new OrderItemDisplayLine('Zone', 'Zone', OrderItemStatus.PENDING));
        lines.push(new OrderItemDisplayLine('Domain', 'Domain', OrderItemStatus.PENDING));
        return lines;
    }
}
