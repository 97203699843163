import * as Types from '@/types';
import {
    GenericSetup,
    OrderItemDisplayLine,
    OrderItemStatus,
    MaybeLoading,
    GenericInjectedRequests,
} from '@/components/setup/setup';
import { DatabaseUserObject } from '@/atomic-components/molecules/panels/panel-rows/panel-row-access/database/database-access';

type OrchestrationJob = Types.ViewTypes.OrchestrationJob;
type VirtualMachine = Types.MachineApi.VirtualMachine;
type APIResponse<T> = Types.UI.APIResponse<T>;
type APISingleResponse<T> = Types.UI.APISingleResponse<T>;
type Webspace = Types.WebhostingApi.Webspace;
type VHost = Types.WebhostingApi.VHost;
type Bundle = Types.BundleApi.Bundle;
type WebspaceUser = Types.WebhostingApi.User;
type Database = Types.DatabaseApi.Database;

export type BundleAdditionalInjectedRequests = {
    loadWebspace: (id: string) => Promise<Webspace>,
    loadVHost: (id: string) => Promise<VHost>,
    loadBundle: (id: string) => Promise<Bundle>,
    updateWebspace: (webspace: Webspace) => Promise<APISingleResponse<Webspace>>,
    updateBundle: (bundle: Bundle) => Promise<APISingleResponse<Bundle>>,
    createWebspaceUser: (user: WebspaceUser, password: string) => Promise<APISingleResponse<WebspaceUser>>,
    createDatabaseUser: (user: DatabaseUserObject, password: string) => Promise<APISingleResponse<Database>>,
    createDatabase: (database: Types.DatabaseApi.Database) => Promise<APISingleResponse<Database>>,
    listDatabase: (bundleId: string) => Promise<APIResponse<Database>>,
}

export type BundleAllInjectedRequests = GenericInjectedRequests & BundleAdditionalInjectedRequests;

type AccessLevel = {
    label: string, value: boolean
};

export class BundleSetup extends GenericSetup {

    // fetched async from API
    private webspace: MaybeLoading<Webspace> = {state: 'waiting'};
    private vHost: MaybeLoading<VHost> = {state: 'waiting'};
    private bundle: MaybeLoading<Bundle> = {state: 'waiting'};
    private database?: Types.DatabaseApi.Database;

    // running async processes
    public bundleNameIsUpdating = false;
    public webspaceNameIsUpdating = false;
    public databaseIsUpdating = false;

    // fetched async from API
    private item: MaybeLoading<VirtualMachine> = {state: 'waiting'};

    // running async processes
    public vmNameIsUpdating = false;

    constructor(
        protected requests: BundleAllInjectedRequests,
        private applyWrapper?: (fn: () => void) => void,
    ) {
        super();
        applyWrapper ??= (fn: () => void) => fn();
    }

    protected jobIsDone = (job: OrchestrationJob) => {
        switch (job.objectType) {
            case 'Webspace':
                if (this.webspace.state == 'waiting') {
                    this.webspace = {state: 'loading'};
                    this.requests.loadWebspace(job.objectId).then(
                        (webspace: Webspace) => {
                            this.webspace = { state: 'loaded', data: webspace };
                        },
                    );
                }
                break;
            case 'VHost':
                if (this.vHost.state == 'waiting') {
                    this.vHost = {state: 'loading'};
                    this.requests.loadVHost(job.objectId).then(
                        (vHost: VHost) => {
                            this.vHost = { state: 'loaded', data: vHost };
                        },
                    );
                }
                break;
            case 'Bundle':
                if (this.bundle.state == 'waiting') {
                    this.bundle = {state: 'loading'};
                    this.requests.loadBundle(job.objectId).then(
                        (bundle: Bundle) => {
                            this.bundle = { state: 'loaded', data: bundle };
                            this.requests.listDatabase(this.bundle.data.id).then(
                                (response: APIResponse<Database>) => {
                                    this.database = response.response.data[0];
                                }
                            )
                        },
                    );
                }
                break;
        }
    }


    protected postprocessOrderItemDisplayLines = (lines: OrderItemDisplayLine[]) => {
        // This triggers when the bundle was ordered without a domain name.
        if (lines.length <= 2) {
            lines.push(new OrderItemDisplayLine('dummy1', '\u00A0', OrderItemStatus.BLANK));
            lines.push(new OrderItemDisplayLine('dummy2', '\u00A0', OrderItemStatus.NODOMAIN));
        }
    }

    protected orderItemDisplayLinesBeforeJobIsLoaded = (): OrderItemDisplayLine[] => {
        // Loading data, to avoid jumping element positions
        const lines: OrderItemDisplayLine[] = [];
        lines.push(new OrderItemDisplayLine('Bundle', 'Bundle', OrderItemStatus.PENDING));
        lines.push(new OrderItemDisplayLine('Webspace', 'Webspace', OrderItemStatus.PENDING));
        lines.push(new OrderItemDisplayLine('dummy1', '\u00A0', OrderItemStatus.BLANK));
        lines.push(new OrderItemDisplayLine('dummy2', '\u00A0', OrderItemStatus.BLANK));
        return lines;
    }

    /*
        FUNCTIONS
    */

    public bundleNameChangeUpdatesWebspaceName = () => {
        if (this.webspace.state !== 'loaded' || this.bundle.state !== 'loaded') {
            throw Error('Webspace or bundle is not yet loaded');
        }
        return this.webspace.data.name === this.bundle.data.name;
    };

    public changeBundleName = (name: string): Promise<void> => {
        // Kindersicherung
        if (this.webspace.state !== 'loaded' || this.bundle.state !== 'loaded') {
            throw Error('Webspace or bundle is not yet loaded');
        }
        const updatePromises: Promise<() => void>[] = [];
        this.bundleNameIsUpdating = true;
        if (this.bundleNameChangeUpdatesWebspaceName()) {
            const newWebspace = {...this.webspace.data, name: name};
            updatePromises.push(
                this.requests.updateWebspace(newWebspace).then(
                    (response: APISingleResponse<Webspace>) => {
                        return () => {
                            this.webspace = { state: 'loaded', data: response.response };
                        };
                    },
                )
            );
        }
        const newBundle = {...this.bundle.data, name: name};
        updatePromises.push(
            this.requests.updateBundle(newBundle).then(
                (response: APISingleResponse<Bundle>) => {
                    return () => {
                        this.bundle = { state: 'loaded', data: response.response };
                    };
                },
            ),
        );
        return Promise.allSettled(updatePromises).then(
            (results: PromiseSettledResult<() => void>[]) => {
                this.applyWrapper(() => {
                    let error = false;
                    for (const result of results) {
                        if (result.status === 'fulfilled') {
                            result.value();
                        } else {
                            error = true;
                        }
                    }
                    this.bundleNameIsUpdating = false;
                    if (error) {
                        throw new Error('Errors while updating (used to keep in editing mode)');
                    }
                });
            },
        );
    };

    public createWebspaceUser = (
        user: Types.WebhostingApi.User,
        password: string,
        accessLevels: Record<string, AccessLevel>
    ) => {
        this.webspaceNameIsUpdating = true;
        void this.requests.createWebspaceUser(user, password)
            .then((response: APISingleResponse<WebspaceUser>) => {
                if (this.webspace.state !== 'loaded') {
                    throw Error('Webspace is not yet loaded');
                }
                if (this.webspace.data.accesses === undefined) {
                    this.webspace.data.accesses = [];
                }
                const access = {
                    userId: response.response.id,
                    ftpAccess: accessLevels.ftpAccess.value,
                    sshAccess: accessLevels.sshAccess.value,
                    statsAccess: accessLevels.statsAccess.value,
                };
                return this.requests.updateWebspace({...this.webspace.data, accesses: [...this.webspace.data.accesses, access]});
            })
            .then((response: APISingleResponse<Webspace>) => {
                this.webspace = { state: 'loaded', data: response.response };
                this.webspaceNameIsUpdating = false
            })
            .catch(() => this.webspaceNameIsUpdating = false);
    }

    public createDatabaseUser = (
        user: DatabaseUserObject,
        password: string,
    ) => {
        this.databaseIsUpdating = true;
        void this.requests.createDatabaseUser(user, password)
            .then((response: APISingleResponse<Database>) => {
                if (this.bundle.state !== 'loaded') {
                    throw Error('Bundle is not yet loaded');
                }
                return this.requests.createDatabase(
                    {
                        'accesses': [
                            {
                                'accessLevel': [
                                    'read',
                                    'write',
                                    'schema'
                                ],
                                'userId': response.response.id,
                            }
                        ],
                        'bundleId': this.bundle.data.id,
                        'name': this.bundle.data.name,
                        'productCode': 'database-mariadb-single-v1-12m', // TODO don't hardcode
                        'storageQuota': 0
                    }
                );
            })
            .then(() => {

                if (this.bundle.state !== 'loaded') {
                    throw Error('Bundle is not yet loaded');
                }
                this.requests.listDatabase(this.bundle.data.id).then(
                    (response: APIResponse<Database>) => {
                        this.database = response.response.data[0];
                    }
                )
                this.databaseIsUpdating = false;
            })
            .catch(() => this.databaseIsUpdating = false);
    }

    /*
        GETTERS
    */

    get webspaceObjectLoaded(): boolean {
        return this.webspace.state === 'loaded';
    }

    get webspaceObject(): Webspace {
        if (this.webspace.state !== 'loaded') {
            throw Error('Webspace is not yet loaded');
        }
        return this.webspace.data;
    }

    get vHostObjectLoaded(): boolean {
        return this.vHost.state === 'loaded';
    }

    get vHostObject(): VHost {
        if (this.vHost.state !== 'loaded') {
            throw Error('Webspace is not yet loaded');
        }
        return this.vHost.data;
    }

    get bundleObjectLoaded(): boolean {
        return this.bundle.state === 'loaded';
    }

    get bundleObject(): Bundle {
        if (this.bundle.state !== 'loaded') {
            throw Error('Webspace is not yet loaded');
        }
        return this.bundle.data;
    }

    get hasWebspaceUser(): boolean {
        if (this.webspace.state === 'loaded') {
            return this.webspace.data.accesses.length > 0;
        }
        return false;
    }

    get hasMultipleWebspaceUsers(): boolean {
        if (this.webspace.state === 'loaded') {
            return this.webspace.data.accesses.length > 1;
        }
        return false;
    }
    get firstCreatedWebspaceUser(): string {
        if (this.webspace.state === 'loaded' && this.hasWebspaceUser) {
            return this.webspace.data.accesses[0].userName;
        }
        return '';
    }

    get hasDatabase(): boolean {
        return this.database !== undefined;
    }

    get webspaceHostname(): string {
        if (this.webspace.state === 'loaded' && this.hasWebspaceUser) {
            return this.webspace.data.hostName;
        }
        return '';
    }

    get databaseUser(): string {
        if (this.database !== undefined) {
            return this.database.accesses[0]?.dbLogin
        }
        return '';
    }

    get databaseName(): string {
        if (this.database !== undefined) {
            return this.database.dbName;
        }
        return '';
    }

    get databaseHostname(): string {
        if (this.database !== undefined) {
           return this.database.hostName;
        }
        return '';
    }

}
