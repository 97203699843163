import {
    FilterCacheService,
    NavigationService,
    OverviewHelperService,
    OverviewModel
} from '../../../../services';
import { LinkListItem } from '../../../molecules/links/link-list/link-list';
import {
    AdditionalActionDataObject
} from '../../../molecules/panels/panel-additional-actions/panel-additional-actions';
import { PanelHeaderData } from '../../../molecules/panels/panel-header/panel-header';

import * as ng from 'angular';

import './dashboard-overviews.scss';

export interface DashboardPanelDataObject {
    mainHeader: LinkListItem | LinkListItem[];
    addtionalActionData?: AdditionalActionDataObject;
    disableSearchBar?: boolean;
    disableExpertView?: boolean;
}

export interface DashboardOverviewDataObject {
    overviewModel: OverviewModel;
    panelHeaderData: PanelHeaderData;
    hidePanelHeaderData?: boolean;
    listOptions?: {
        limit?: number;
        additionalFilters?: any[];
        objectId?: string;
        sort?: {};
    };
    serviceItemList: any[];
    hasMoreItems?: boolean;
    hideOnEmpty?: boolean;
}

export interface DashboardOverviewObject {
    panelData: DashboardPanelDataObject;
    simpleFilter?: { show: boolean; value: string};
    overviewsData: DashboardOverviewDataObject[];
}

export class OrganismDashboardOverviewsController implements ng.IController {

    public static $inject: string[] = [
        '$state',
        '$timeout',
        'filterCache',
        'navigation',
        'overviewHelper'
    ];

    public expandWrapperValue: boolean;
    public hideHeader: boolean;
    public dashboardOverview: DashboardOverviewObject;
    public overviewLoaded = false;
    public elementsOnDashboard = 5;
    public simpleFilter: { show: boolean; value: string } = { show: false, value: '' };
    public showObjectId: boolean;

    private displayMoreSearchResults = false;

    constructor(
        private $state: ng.ui.IStateService,
        private $timeout: ng.ITimeoutService,
        private filterCache: FilterCacheService,
        private navigation: NavigationService,
        private overviewHelper: OverviewHelperService
    ) {}

    public $onInit = () => {
        if ([undefined, null].indexOf(this.dashboardOverview.simpleFilter) < 0) {
            this.simpleFilter = this.dashboardOverview.simpleFilter;
        }
        this.dashboardOverview.overviewsData.forEach(
            (overview) => overview.overviewModel.availableFilters.forEach((filter) => filter.value = null)
        );
        this.hideHeader = this.hideHeader || false;
        this.expandWrapperValue = false;
        this.$timeout(() => this.onLoad());
    };

    public overviewLoad = () => {
        this.$timeout(this.onLoad, 50);
    };

    public displayMoreSearchResultsLink = (serviceItemList) => {
        if (typeof serviceItemList === 'undefined') {
            return false;
        }
        return this.displayMoreSearchResults && (serviceItemList.length > this.elementsOnDashboard);
    };

    public setFilterCache = () => {
        let targetRoute: string;
        let targetCache: string;
        this.displayMoreSearchResults = true;
        switch (this.$state.current.name) {
            case 'domain.dashboard': {
                targetRoute = 'domain.domains.overview';
                targetCache = 'domain.domain';
                break;
            }
            case 'dns.dashboard': {
                targetRoute = 'dns.zones.overview';
                targetCache = 'domain.domain';
                break;
            }
            case 'ssl.dashboard': {
                targetRoute = 'ssl.certificates.overview';
                targetCache = 'ssl.certificate';
                break;
            }
            case 'storage.dashboard': {
                targetRoute = 'storage.storage-products.overview';
                targetCache = 'storage.storage-product';
                break;
            }
            case 'email.dashboard': {
                targetRoute = 'email.mailboxes.overview';
                targetCache = 'email.account';
                break;
            }
            case 'webhosting.dashboard': {
                targetRoute = 'webhosting.webspaces.jobs';
                targetCache = 'webhosting.job';
                break;
            }
            case 'database.dashboard': {
                targetRoute = 'database.databases.overview';
                targetCache = 'databases';
                break;
            }
            case 'network.dashboard': {
                targetRoute = 'network.networks.overview';
                targetCache = 'network';
                break;
            }
            default: {
                this.displayMoreSearchResults = false;
                break;
            }
        }
        if (this.displayMoreSearchResults) {
            this.filterCache.setFilter(targetCache, this.simpleFilter, {});
            this.navigation.go(targetRoute, '', {reload: false});
        }
    };

    public onLoad = (): Promise<void> => {
        const overviewPromises = [];
        this.overviewLoaded = false;

        for (const overview of this.dashboardOverview.overviewsData {
            const currentPage = 1;
            let paginationLimit = 5;
            let additionalFilters = undefined;
            let objectId = null;
            let sort = null;

            if (overview.listOptions !== undefined) {
                paginationLimit = overview.listOptions.limit || paginationLimit;
                additionalFilters = overview.listOptions.additionalFilters || [];
                objectId = overview.listOptions.objectId || objectId;
                sort = overview.listOptions.sort || sort;
            }

            this.setDisplayRowsLimit(paginationLimit);

            overviewPromises.push(overview.overviewModel.list(
                paginationLimit,
                currentPage,
                this.simpleFilterValue,
                additionalFilters,
                objectId,
                0,
                sort
            ));
        }

        return Promise.all(overviewPromises).then(
            (results) => {
                results.map((res, index) => {
                    this.onPageData(res, index);
                });
            },
            (err) => {
                console.error('overview promise error', err);
            }
        );
    };

    public clickRow = (item: any, service: string, objectType: string, event: any,  itemEdit?: boolean) => {
        if ([undefined, null].indexOf(event) < 0 && [undefined, null].indexOf(event.stopPropagation) < 0) {
            event.stopPropagation();
        }

        this.overviewHelper.handleRowClickRoutes(service, objectType, item, undefined, undefined, false, itemEdit);
    };

    public clickBundle = (bundleId: string, event: any) => {
        if ([undefined, null].indexOf(event) < 0 && [undefined, null].indexOf(event.stopPropagation) < 0) {
            event.stopPropagation();
        }
        this.navigation.go('bundle.id.dashboard', { bundleId: bundleId });
    };

    /**
     *  Action link availabily check - call overview model method
     */
    public actionLinkClickable = (linkMethod: string | boolean) => {
        if ([undefined, null, '', true, false].indexOf(linkMethod) >= 0) {
            return typeof linkMethod === 'boolean' ? linkMethod : true;
        }
        // toDo - when routes of level down components given - first build all product dashboards
        // return this.overviewModel.actionLinksAvailabilities[linkMethod](this.overviewData.serviceItemList);
    };

    public getHideOnEmpty(overview: { hideOnEmpty: any; serviceItemList: { length: number } }) {
        return !(overview.hideOnEmpty && overview.serviceItemList.length === 0);
    }

    private setDisplayRowsLimit(paginationLimit) {
        this.elementsOnDashboard = parseInt(paginationLimit, null) - 1;
    }

    private onPageData = (result: any, index: number) => {
        this.dashboardOverview.overviewsData[index].serviceItemList = result.data.slice(0, 4);
        this.dashboardOverview.overviewsData[index].hasMoreItems = result.data.length > 4;

        this.$timeout(() => this.overviewLoaded = true);
    };

    public get simpleFilterValue() {
        return this.simpleFilter.value;
    }

    public set simpleFilterValue(value) {
        if (value.length > 0) {
            this.displayMoreSearchResults = true;
            this.simpleFilter.show = true;
            this.simpleFilter.value = value;
        } else {
            this.displayMoreSearchResults = false;
            this.simpleFilter.show = false;
            this.simpleFilter.value = '';
        }
    }

    public get globals() {
        // For some reason, in a few instances, the `.locals` property is null
        // and therefore leads to an error when trying to access `.globals` 😕.
        // Just catch the error and return undefined.
        return (this.$state.$current.locals || {} as any).globals;
    }
}

export class OrganismDashboardOverviewsComponent implements ng.IComponentOptions {
    public bindings = {
        dashboardOverview: '<',
        hideHeader: '<',
        showObjectId: '<'
    };
    public controller =  OrganismDashboardOverviewsController;
    public controllerAs = 'DashboardOverviewsCtrl';
    public template = require('./dashboard-overviews.html');
}
