/**
 * RobotTranslate
 *
 * wird in app.ts als $translateProvider hinzugefuegt.
 * wird durch translate-ng.ts in das angular ökosystem eingespeißt.
 *
 * Die hauptmethode instant() nimmt eine Translation ID und such sich aus der
 * json datei von der aktuelle Sprache die Übersetzung heraus.
 *
 * Um die Sprache zu ändern wird use($LANG) aufgerufen, wobei $LANG dem ISO
 * Format entspricht.
 */

import ng from 'angular';
// @ts-ignore dynamic json import
import DE from '@/languages/generated.de_DE.json?language';
// @ts-ignore dynamic json import
import US from '@/languages/generated.en_US.json?language';
// @ts-ignore dynamic json import
import TR from '@/languages/generated.tr_TR.json?language';
// @ts-ignore dynamic json import
import FR from '@/languages/generated.fr_FR.json?language';
// @ts-ignore dynamic json import
import FR_DEV from '@/languages/generated.fr_DEV.json?language';

import { UtilHelperService } from '@/services/helpers';

export type ITranslationKey = string;
export interface ITranslateParams {
    [key: string]: string;
}
type ITranslations = typeof DE | typeof US | typeof TR;

const requestLanguage = (lang: string): ITranslateParams => {
    const request = new XMLHttpRequest();
    request.open('GET', lang, false);
    request.send();
    if (request.status === 200) {
        return JSON.parse(request.responseText) as ITranslateParams;
    } else {
        return {};
    }
};

const languageMap: { [lang: string]: { exists: boolean; request: () => ITranslateParams } } = {
    de_DE: {
        exists: false,
        request: () => requestLanguage(DE)
    },
    en_US: {
        exists: false,
        request: () => requestLanguage(US)
    },
    tr_TR: {
        exists: false,
        request: () => requestLanguage(TR)
    },
    fr_FR: {
        exists: false,
        request: () => requestLanguage(FR)
    },
    fr_DEV: {
        exists: false,
        request: () => requestLanguage(FR_DEV)
    }
};

export type LanguageTypes = 'de_DE' | 'en_US' | 'tr_TR';

export type TranslationStatic = {
    de_DE: ITranslateParams | ITranslations | undefined;
    en_US: ITranslateParams | ITranslations | undefined;
    tr_TR: ITranslateParams | ITranslations | undefined;
};

export class RobotTranslate implements ng.IServiceProvider {
    private static translations: TranslationStatic = {
        de_DE: undefined,
        en_US: undefined,
        tr_TR: undefined
    } as TranslationStatic;
    private static _prefLanguage: LanguageTypes;
    private static _fallbackLanguage: LanguageTypes;
    private static _devMode: boolean;
    private instance: RobotTranslate;

    public constructor(
        private $interpolate: ng.IInterpolateService
    ) {}

    public static translate(
        key: ITranslationKey
    ): string {
        const lang = RobotTranslate._prefLanguage || RobotTranslate._fallbackLanguage;

        if (!key) {
            return '';
        }
        if (!RobotTranslate.translations[lang] || RobotTranslate._devMode) {
            if (!RobotTranslate.translations[lang]) {
                console.error('Translations missing', key);
            }

            return key;
        }

        let translation: string;
        if (key.includes('.')) {
            translation = UtilHelperService.getValueAtPathOfObject(RobotTranslate.translations[lang], key);
        } else {
            translation = (RobotTranslate.translations[lang] as { [translationId: string]: string })[key];
        }
        return translation;
    }

    public $get = (
        $interpolate: ng.IInterpolateService
    ): RobotTranslate => {
        if (!this.instance) {
            this.instance = new RobotTranslate($interpolate);
        }
        return this.instance;
    };

    public instant(
        key: ITranslationKey,
        interpolateParams?: ITranslateParams,
        $compile?: ng.ICompileService,
        $rootScope?: ng.IRootScopeService
    ): string {
        const translation = RobotTranslate.translate(key);
        let scope: any = $rootScope;
        if (!scope) {
            scope = {};
        }
        let paramsProps: string[] = [];
        if (interpolateParams) {
            paramsProps = Object.getOwnPropertyNames(interpolateParams);
        }
        if (paramsProps.length > 0) {
            for (const element of paramsProps) {
                scope[element] = interpolateParams[element];
            }
            return this.$interpolate(translation)(scope);
        }
        if (!translation) {
            return key;
        }
        return translation;
    }

    public fallbackLanguage(language: LanguageTypes): RobotTranslate {
        RobotTranslate._fallbackLanguage = language;
        return this;
    }

    public setDevMode(devMode: boolean): RobotTranslate {
        RobotTranslate._devMode = devMode;
        return this;
    }

    /**
     * @warn Wenn mehere Funktionen hintereinander gereiht werden muss use()
     * immer an letzter stelle stehen!
     */
    public use(langKey?: LanguageTypes | string): string | RobotTranslate {
        if (langKey) {
            RobotTranslate._prefLanguage = langKey as LanguageTypes;
            this._setup();
            return this;
        }
        return RobotTranslate._prefLanguage;
    }

    private _setup(): RobotTranslate {
        try {
            const lang = RobotTranslate._prefLanguage || RobotTranslate._fallbackLanguage;
            if (!languageMap[lang].exists) {
                RobotTranslate.translations[lang] = languageMap[lang].request();
                languageMap[lang].exists = true;
            }
        } catch (error) {
            // angularjs only throwing generic error
            console.error(error);
        }
        return this;
    }
}

export class Translation {
    private key: string;
    private translation: string;

    constructor(key: string, translation: string) {
        this.key = key;
        this.translation = translation;
    }

    public getKey(): string {
        return this.key;
    }

    public getTranslation(): string {
        return this.translation;
    }
}
