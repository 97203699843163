import * as ng from 'angular';
import { BillingCycleToContractPeriodeConst, UiRights } from '@/configuration';
import {
    AccountModelService,
    AuthContextService,
    BackupModelService,
    CronjobOverviewModel,
    DatabaseModelService,
    DatabaseOverviewModel,
    DatabaseUserModelService,
    FilterCacheService,
    InstallerModelService,
    MachineModelService,
    MailboxModelService,
    MailboxOverviewModel,
    ManagedMachineOverviewModel,
    ModelHelper,
    NavigationService, PriceCacheService,
    ProductHelperService,
    ProductsModelService,
    RedirectOnNotFoundCallback,
    ResourceModelService,
    RestrictionHelperService,
    TldListService,
    UserSettingsManagerService,
    VhostModelService,
    VhostOverviewModel,
    WebhostingHelperService,
    WebhostingUserModelService,
    WebspaceModelService,
    WebspaceOverviewModel
} from '@/services';
import * as stateUtilityFunctions from '@/services/state-utility-functions';
import * as Types from '@/types';
import { getDefaultCronjob } from '@/configuration/cronjob-default';

export const MACHINE_MANAGED_ROUTES = [
    {
        name: 'managed-servers',
        state: {
            abstract: true,
            parent: 'authenticated',
            url: '/managed-servers'
        }
    },
    {
        name: 'managed-servers.new',
        state: {
            resolve: {
                appList: (installerModel: InstallerModelService) => {
                    return installerModel.list('app', {});
                }
            },
            data: {
                isGranted: UiRights.MACHINE_VM_CREATE
            },
            url: '/new',
            views: {
                'content@authenticated': {
                    template: '<template-machine-wizard></template-machine-wizard>'
                }
            }
        }
    },
    {
        name: 'managed-servers.setup',
        state: {
            abstract: true,
            url: '/setup',
        }
    },
    {
        name: 'managed-servers.setup.orderId',
        state: {
            data: {
                isGrantedAll: [ UiRights.BIL_BUNDLE_LIST, UiRights.BIL_BUNDLE_CREATE ]
            },
            resolve: {
                orderId: (
                    $stateParams: ng.ui.IStateParamsService,
                ) => {
                    return $stateParams.orderId;
                },
            },
            url: '/{orderId}',
            views: {
                'content@authenticated': {
                    template: '<template-machine-managed-setup order-id="$resolve.orderId"></template-machine-managed-setup>'
                }
            }
        }
    },
    {
        name: 'managed-servers.overview',
        state: {
            data: {
                isGranted: UiRights.MACHINE_VM_LIST
            },
            onEnter: (
                navigation: NavigationService,
                machineModel: MachineModelService
            ) => {
                machineModel.listWithoutPagination(
                    2, null, { field: 'virtualMachineManagementType', value: 'platform' }
                    ).then((apiResponse) => {
                        if ([null, undefined].indexOf(apiResponse.data) < 0 && apiResponse.data.length === 1) {
                            navigation.go('managed-servers.id.dashboard', { machineId: apiResponse.data[0].id });
                        }
                });
            },
            resolve: {
                extendedFilters: ($stateParams: ng.ui.IStateParamsService, filterCache: FilterCacheService) => {
                    if ($stateParams.fancyFilter !== undefined && $stateParams.fancyFilter !== '') {
                        return JSON.parse($stateParams.fancyFilter);
                    }
                    return filterCache.resolveExtendedFilters('ManagedMachine');
                },
                overviewModel: (
                    managedMachineOverviewModel: ManagedMachineOverviewModel
                ) => managedMachineOverviewModel,
                overviewUiSettings: (userSettingsManager: UserSettingsManagerService) => {
                    return userSettingsManager.getSettingsForOverview('ManagedMachineOverviewCtrl');
                },
                showObjectId: (userSettingsManager: UserSettingsManagerService) => {
                    return userSettingsManager.isShowId();
                },
                simpleFilter: (filterCache: FilterCacheService) => {
                    return filterCache.resolveSimpleFilter('ManagedMachine');
                }
            },
            url: '/overview',
            views: {
                'content@authenticated': {
                    template: `<template-machine-managed-overview
                        simple-filter="$resolve.simpleFilter"
                        extended-filters="$resolve.extendedFilters"
                        overview-ui-settings="$resolve.overviewUiSettings"
                        overview-model="$resolve.overviewModel"
                        show-object-id="$resolve.showObjectId"
                    ></template-machine-managed-overview>`
                }
            }
        }
    },
    {
        name: 'managed-servers.id',
        state: {
            abstract: true,
            data: {
                isGranted: UiRights.MACHINE_VM_LIST
            },
            resolve: {
                databaseRessource: ($stateParams: ng.ui.IStateParamsService, resourceModel: ResourceModelService) => {
                    const filters = { field: 'virtualMachineId', value: $stateParams.machineId };
                    return resourceModel.resourceList(null, 'Database', null, null, filters).then((res) => {
                        return res.data.length !== 0
                            ? res.data[0]
                            : null;
                    });
                },
                machine: (
                    $stateParams: ng.ui.IStateParamsService,
                    machineModel: MachineModelService,
                    redirectOnNotFound: RedirectOnNotFoundCallback
                ) => {
                    return machineModel.findOneById($stateParams.machineId).then(null, redirectOnNotFound);
                },
                machineAccount: (
                    accountModel: AccountModelService,
                    machine: Types.MachineApi.VirtualMachine,
                    redirectOnNotFound: RedirectOnNotFoundCallback
                ) => {
                    if (machine.accountId === AuthContextService.account.id) {
                        return AuthContextService.account;
                    }

                    return accountModel.findOne(machine.accountId).then(null, redirectOnNotFound);
                },
                webserverRessource: ($stateParams: ng.ui.IStateParamsService, resourceModel: ResourceModelService) => {
                    const filters = { field: 'virtualMachineId', value: $stateParams.machineId };
                    return resourceModel.resourceList(null, 'Webserver', null, null, filters).then((res) => {
                        return res.data.length !== 0
                            ? res.data[0]
                            : null;
                    });
                },
                // tslint:disable-next-line:object-literal-sort-keys
                pool: (
                    databaseRessource,
                    redirectOnNotFound: RedirectOnNotFoundCallback,
                    resourceModel: ResourceModelService,
                    webserverRessource
                ) => {
                    let poolId = null;

                    if (databaseRessource !== null) {
                        poolId = databaseRessource.poolId;
                    } else if (webserverRessource !== null) {
                        poolId = webserverRessource.poolId;
                    }

                    if (poolId !== null) {
                        return resourceModel.findOne('Pool', poolId).then(null, redirectOnNotFound);
                    }

                    return null;
                },
                hasOnlyOneServer: (machineModel: MachineModelService) => {
                    return machineModel.listWithoutPagination(
                        2, null, { field: 'virtualMachineManagementType', value: 'platform' }
                    ).then((apiResponse) => {
                        return [null, undefined].indexOf(apiResponse.data) < 0 && apiResponse.data.length === 1;
                    });
                }
            },
            url: '/id/{machineId}'
        }
    },
    {
        name: 'managed-servers.id.dashboard',
        state: {
            url: '/dashboard',
            views: {
                'content@authenticated': {
                    template: `<template-machine-managed-dashboard
                                machine="$resolve.machine"
                                database-ressource="$resolve.databaseRessource"
                                webserver-ressource="$resolve.webserverRessource"
                                has-only-one-server="$resolve.hasOnlyOneServer"
                                pool="$resolve.pool"
                                ></template-machine-managed-dashboard>`
                }
            }
        }
    },
    {
        name: 'managed-servers.id.new-project1',
        state: {
            data: {
                // user needs one of this rights
                isGrantedAny: [UiRights.WEB_OBJECT_LIST, UiRights.DB_DATABASE_LIST]
            },
            url: '/new1',
            views: {
                'content@authenticated': {
                    template: `<template-machine-managed-project-new
                                machine="$resolve.machine"
                                database-ressource="$resolve.databaseRessource"
                                webserver-ressource="$resolve.webserverRessource"
                                ></template-machine-managed-project-new>`
                }
            }
        }
    },
    {
        name: 'managed-servers.id.new-project',
        state: {
            data: {
                // user needs one of this rights
                isGrantedAny: [UiRights.WEB_OBJECT_LIST, UiRights.DB_DATABASE_LIST]
            },
            url: '/new',
            views: {
                'content@authenticated': {
                    template: `<template-managed-project-wizard-new
                        machine="$resolve.machine"
                        database-ressource="$resolve.databaseRessource"
                        webserver-ressource="$resolve.webserverRessource"
                    ></template-managed-project-wizard-new>`
                }
            }
        }
    },
    {
        name: 'managed-servers.id.cancel',
        state: {
            data: {
                isGranted: UiRights.MACHINE_VM_DELETE
            },
            url: '/cancel',
            views: {
                'content@authenticated': {
                    template: `<template-machine-managed-delete
                                machine="$resolve.machine"
                                ></template-machine-managed-delete>`
                }
            }
        }
    },
    {
        name: 'managed-servers.id.domains',
        state: {
            abstract: true,
            url: '/domains'
        }
    },
    {
        name: 'managed-servers.id.domains.new',
        state: {
            params: {
                // benötigen wir hier eigentlich die Parameter??
                domainType: { value: null, squash: true },
                machineId: { value: null, squash: true },
                webspaceId: { value: null, squash: true }
            },
            url: '/new',
            resolve: {
                managedServerWebspaceItems: (webserverRessource, webspaceModel: WebspaceModelService) => {
                    const filters = {
                        subFilter: [
                            { field: 'webserverId', value: webserverRessource.id },
                            { field: 'WebspaceStatus', value: 'active' }
                        ],
                        subFilterConnective: 'AND'
                    };
                    return webspaceModel.listWithoutPagination(
                        100,
                        null,
                        filters
                    )
                        .then((res) => res.data);
                }
            },
            views: {
                'content@authenticated': {
                    // tslint:disable-next-line:max-line-length
                    template: `<template-domain-wizard
                            machine="$resolve.machine"
                            webserver-ressource="$resolve.webserverRessource"
                            managed-server-webspace-items="$resolve.managedServerWebspaceItems"
                        ></template-domain-wizard>`
                }
            }
        }
    },
    {
        name: 'managed-servers.id.mailboxes',
        state: {
            abstract: true,
            url: '/mailboxes'
        }
    },
    {
        name: 'managed-servers.id.mailboxes.overview',
        state: {
            data: {
                isGranted: UiRights.EMAIL_MAILBOX_LIST
            },
            resolve: {
                extendedFilters: (filterCache: FilterCacheService) => {
                    return filterCache.resolveExtendedFilters('Mailboxes');
                },
                overviewModel: (mailboxOverviewModel: MailboxOverviewModel) => mailboxOverviewModel,
                overviewUiSettings: (userSettingsManager: UserSettingsManagerService) => {
                    return userSettingsManager.getSettingsForOverview('EmailOverviewCtrl');
                },
                simpleFilter: (filterCache: FilterCacheService) => {
                    return filterCache.resolveSimpleFilter('Mailboxes');
                },
                comeFrom: () => 'managed-servers'
            },
            url: '/overview',
            views: {
                'content@authenticated': {
                    template: `<template-email-overview
                                    simple-filter="$resolve.simpleFilter"
                                    extended-filters="$resolve.extendedFilters"
                                    overview-ui-settings="$resolve.overviewUiSettings"
                                    overview-model="$resolve.overviewModel"
                                    come-from="$resolve.comeFrom"
                                ></template-email-overview>`
                }
            }
        }
    },
    {
        name: 'managed-servers.id.mailboxes.new',
        state: {
            data: {
                isGrantedAny: [UiRights.EMAIL_IMAPMAILBOX_CREATE, UiRights.EMAIL_FORWARDER_CREATE]
            },
            url: '/new',
            views: {
                'content@authenticated': {
                    // tslint:disable-next-line:max-line-length
                    template: '<template-email-wizard machine="$resolve.machine" pool="$resolve.pool"></template-email-wizard>'
                }
            }
        }
    },
    {
        name: 'managed-servers.id.mailboxes.id',
        state: {
            abstract: true,
            resolve: {
                mailbox: (
                    $stateParams: ng.ui.IStateParamsService,
                    mailboxModel: MailboxModelService,
                    redirectOnNotFound: RedirectOnNotFoundCallback
                ) => {
                    return mailboxModel.findOne($stateParams.mailboxId)
                        .then(stateUtilityFunctions.check404('Mailbox not found'))
                        .then(null, redirectOnNotFound);
                }
            },
            url: '/id/{mailboxId}'
        }
    },
    {
        name: 'managed-servers.id.mailboxes.id.edit',
        state: {
            data: {
                isGrantedAny: [UiRights.EMAIL_MAILBOX_EDIT, UiRights.EMAIL_CATCHALL_EDIT]
            },
            resolve: {
                maxMailingListMembers: (mailbox: Types.EmailApi.Mailbox, productsModel: ProductsModelService) => {
                    return productsModel.findOne(mailbox.productCode).then(
                        (reply) => {
                            return ProductHelperService.wrapSpecificationItems(
                                reply?.response?.specificationItems
                            ).members?.intValue || 0;
                        }
                    );
                },
                showObjectId: (userSettingsManager: UserSettingsManagerService) => {
                    return userSettingsManager.isShowId();
                },
                comeFrom: () => 'managed-servers'
            },
            url: '/edit',
            views: {
                'content@authenticated': {
                    template: `<template-mailbox-edit
                                    show-object-id="$resolve.showObjectId"
                                    mailbox="$resolve.mailbox"
                                    max-mailing-list-members="$resolve.maxMailingListMembers"
                                    come-from="$resolve.comeFrom"
                                ></template-mailbox-edit>`
                }
            }
        }
    },
    {
        name: 'managed-servers.id.mailboxes.id.restrictions',
        state: {
            data: {
                isGrantedAny: [UiRights.EMAIL_MAILBOX_EDIT, UiRights.EMAIL_CATCHALL_EDIT]
            },
            resolve: {
                restrictionHelper: (
                    mailbox,
                    restrictionHelper: RestrictionHelperService,
                    mailboxModel: MailboxModelService
                ) => {
                    restrictionHelper.init(mailbox, 'email', mailboxModel, 'mailboxModifyRestrictions');
                    return restrictionHelper;
                },
                showObjectId: (userSettingsManager: UserSettingsManagerService) => {
                    return userSettingsManager.isShowId();
                }
            },
            url: '/restrictions',
            views: {
                'content@authenticated': {
                    template: `<template-restrictions-edit
                        api-object="$resolve.mailbox"
                        restriction-helper="$resolve.restrictionHelper"
                        show-object-id="$resolve.showObjectId"
                    ></template-restrictions-edit>`
                }
            }
        }
    },
    {
        name: 'managed-servers.id.webspaces',
        state: {
            abstract: true,
            url: '/webspaces'
        }
    },
    {
        name: 'managed-servers.id.webspaces.overview',
        state: {
            data: {
                isGranted: UiRights.WEB_OBJECT_LIST
            },
            resolve: {
                extendedFilters: (filterCache: FilterCacheService) => {
                    return filterCache.resolveExtendedFilters('Webspaces');
                },
                overviewModel: (webspaceOverviewModel: WebspaceOverviewModel) => webspaceOverviewModel,
                overviewUiSettings: (userSettingsManager: UserSettingsManagerService) => {
                    return userSettingsManager.getSettingsForOverview('WebspaceOverviewCtrl');
                },
                simpleFilter: (filterCache: FilterCacheService) => {
                    return filterCache.resolveSimpleFilter('Webspaces');
                }
            },
            url: '/overview',
            views: {
                'content@authenticated': {
                    template: `<template-webspace-overview
                                    simple-filter="$resolve.simpleFilter"
                                    extended-filters="$resolve.extendedFilters"
                                    overview-ui-settings="$resolve.overviewUiSettings"
                                    overview-model="$resolve.overviewModel"
                                ></template-webspace-overview>`
                }
            }
        }
    },
    {
        name: 'managed-servers.id.webspaces.new',
        state: {
            data: {
                isGranted: UiRights.WEB_WEBSPACE_CREATE
            },
            resolve: {
                profiles: (vhostModel: VhostModelService) => {
                    return vhostModel.listProfiles('').then((answer) => {
                        return answer;
                    });
                }
            },
            url: '/new',
            views: {
                'content@authenticated': {
                    template: `<template-webspace-wizard
                                machine="$resolve.machine"
                                pool="$resolve.pool"
                                webserver-ressource="$resolve.webserverRessource"
                                profiles="$resolve.profiles"
                            ></template-webspace-wizard>`
                }
            }
        }
    },
    {
        name: 'managed-servers.id.webspaces.id',
        state: {
            abstract: true,
            resolve: {
                webspace: (
                    $stateParams: ng.ui.IStateParamsService,
                    redirectOnNotFound: RedirectOnNotFoundCallback,
                    webspaceModel: WebspaceModelService
                ) => {
                    if ($stateParams.webspaceId === null) {
                        return [];
                    }
                    return webspaceModel.findOne($stateParams.webspaceId).then(null, redirectOnNotFound);
                }
            },
            url: '/id/{webspaceId}'
        }
    },
    {
        name: 'managed-servers.id.webspaces.id.edit',
        state: {
            data: {
                isGranted: UiRights.WEB_WEBSPACE_EDIT
            },
            resolve: {
                showObjectId: (userSettingsManager: UserSettingsManagerService) => {
                    return userSettingsManager.isShowId();
                }
            },
            url: '/edit',
            views: {
                'content@authenticated': {
                    template: `<template-webspace-edit
                                    webspace="$resolve.webspace"
                                    show-object-id="$resolve.showObjectId"
                                ></template-webspace-edit>`
                }
            }
        }
    },
    {
        name: 'managed-servers.id.webspaces.id.vhosts',
        state: {
            abstract: true,
            url: '/domains'
        }
    },
    {
        data: {
            isGranted: UiRights.WEB_OBJECT_LIST
        },
        name: 'managed-servers.id.webspaces.id.vhosts.overview',
        state: {
            resolve: {
                extendedFilters: (filterCache: FilterCacheService) => {
                    return filterCache.resolveExtendedFilters('domain.domain');
                },
                overviewModel: (vhostOverviewModel: VhostOverviewModel) => vhostOverviewModel,
                overviewUiSettings: (userSettingsManager: UserSettingsManagerService) => {
                    return userSettingsManager.getSettingsForOverview('WebspaceOverviewCtrl');
                },
                simpleFilter: (filterCache: FilterCacheService) => {
                    return filterCache.resolveSimpleFilter('domain.domain');
                }
            },
            url: '/overview',
            views: {
                'content@authenticated': {
                    template: `<template-webhosting-vhost-overview
                                    simple-filter="$resolve.simpleFilter"
                                    extended-filters="$resolve.extendedFilters"
                                    overview-ui-settings="$resolve.overviewUiSettings"
                                    overview-model="$resolve.overviewModel"
                                    webspace="$resolve.webspace"
                                ></template-webhosting-vhost-overview>`
                }
            }
        }
    },
    {
        name: 'managed-servers.id.webspaces.id.vhosts.new',
        state: {
            data: {
                isGranted: UiRights.WEB_VHOST_CREATE
            },
            params: {
                domainType: {value: null, squash: true}
            },
            resolve: {
                ownerAccount: (webspace, accountModel: AccountModelService) => {
                    if (AuthContextService.account.id === webspace.accountId) {
                        return AuthContextService.account;
                    }
                    return accountModel.findOne(webspace.accountId);
                }
            },
            url: '/new',
            views: {
                'content@authenticated': {
                    // tslint:disable-next-line: max-line-length
                    template: `<template-domain-wizard
                        machine="$resolve.machine"
                        webspace="$resolve.webspace"
                        owner-account="$resolve.ownerAccount"
                    ></template-domain-wizard>`
                }
            }
        }
    },
    {
        name: 'managed-servers.id.webspaces.id.vhosts.id',
        state: {
            abstract: true,
            data: {
                isGranted: UiRights.WEB_VHOST_EDIT
            },
            resolve: {
                domain: () => null,
                domainType: () => 'vhost',
                phpIni: ($stateParams: ng.ui.IStateParamsService, vhostModel: VhostModelService) => {
                    return vhostModel.vhostPhpIniList($stateParams.vHostId);
                },
                phpVersions: (vhostModel: VhostModelService) => {
                    return vhostModel.availablePhpVersions();
                },
                vHost: (vhostModel: VhostModelService, $stateParams: ng.ui.IStateParamsService) => {
                    const filter = {field: 'vhostId', value: $stateParams.vHostId};

                    return vhostModel.list(null, 1, filter, null).then(ModelHelper.returnFindSingleData);
                },
                webspace: (vHost, webspaceModel: WebspaceModelService) => {
                    const filter = {field: 'WebspaceId', value: vHost.webspaceId};
                    return webspaceModel.list(null, 1, filter)
                    .then(ModelHelper.returnFindSingleData);
                }
            },
            url: '/id/{vHostId}'
        }
    },
    {
        name: 'managed-servers.id.webspaces.id.vhosts.id.edit',
        state: {
            resolve: {
                phpConfigurationMetaData: (vhostModel: VhostModelService) => {
                    return vhostModel.phpConfigurationMetadata().then((answer) => {
                        return answer;
                    });
                },
                phpIniDefault: (vHost, webspace, webhostingHelper: WebhostingHelperService) => {
                    return webhostingHelper.getDefaultPhpIniSet(webspace.id, vHost.phpVersion, false);
                },
                phpIniList: (phpIni) => {
                    return phpIni;
                },
                phpVersions: (phpVersions) => {
                    return phpVersions;
                },
                profiles: (webspace, vhostModel: VhostModelService) => {
                    return vhostModel.listProfiles(webspace.id).then((answer) => {
                        return answer;
                    });
                },
                multiPartTlds: (tldList: TldListService) => tldList.getMultiPartTlds()
            },
            url: '/edit',
            views: {
                'content@authenticated': {
                    template: `<template-domain-edit
                        webspace="$resolve.webspace"
                        v-host="$resolve.vHost"
                        domain-type="$resolve.domainType"
                        php-ini-list="$resolve.phpIniList"
                        php-ini-default="$resolve.phpIniDefault"
                        profiles="$resolve.profiles"
                        php-versions="$resolve.phpVersions"
                        php-configuration-meta-data="$resolve.phpConfigurationMetaData"
                        multi-part-tlds="$resolve.multiPartTlds"
                    ></template-domain-edit>`
                }
            }
        }
    },
    {
        name: 'managed-servers.id.webspaces.id.vhosts.id.locations-edit',
        state: {
            resolve: {
                phpIni: (vHost, vhostModel: VhostModelService) => {
                    return vhostModel.vhostPhpIniList(vHost.id).then(
                        (reply) => {
                            return reply;
                        }
                    );
                }
            },
            url: '/locations-edit',
            views: {
                'content@authenticated': {
                    template: `<template-domain-locations
                        webspace="$resolve.webspace"
                        v-host="$resolve.vHost"
                        domain="$resolve.domain"
                        php-ini="$resolve.phpIni"
                    ></template-domain-locations>`
                }
            }
        }
    },
    {
        name: 'managed-servers.id.webspaces.id.vhosts.id.app-install',
        state: {
            resolve: {
                apps: (installerModel: InstallerModelService) => {
                    return installerModel.list('app', {});
                }
            },
            url: '/app-install',
            views: {
                'content@authenticated': {
                    template: `<template-domain-app-install
                        apps="$resolve.apps"
                        webspace="$resolve.webspace"
                        domain="$resolve.domain"
                        v-host="$resolve.vHost"
                        database-ressource="$resolve.databaseRessource"
                        ></template-domain-app-install>`
                }
            }
        }
    },
    {
        name: 'managed-servers.id.webspaces.id.vhosts.id.directory-protection',
        state: {
            url: '/directory-protection',
            views: {
                'content@authenticated': {
                    template: `<template-webhosting-vhost-directory-protection
                        webspace="$resolve.webspace"
                        v-host="$resolve.vHost"
                    ></template-webhosting-vhost-directory-protection>`
                }
            }
        }
    },
    {
        name: 'managed-servers.id.webspaces.id.php-boost',
        state: {
            data: {
                isGrantedAll: [
                    UiRights.BIL_LIST_PRICES_WEBHOSTING,
                    UiRights.WEB_WEBSPACE_EDIT
                ]
            },
            resolve: {
                billingCycle: (priceCache: PriceCacheService, webspace) => {
                    return priceCache.getPriceByProductCode(
                        webspace.productCode,
                        webspace.accountId
                    ).then((reply) => BillingCycleToContractPeriodeConst[reply[0].contractPeriod]);
                }
            },
            url: '/php-boost',
            views: {
                'content@authenticated': {
                    template: '<template-webspace-php-boost webspace="$resolve.webspace" billing-cycle="$resolve.billingCycle"></template-webspace-php-boost>'
                }
            }
        }
    },
    {
        name: 'managed-servers.id.webspaces.id.storage',
        state: {
            data: {
                isGranted: UiRights.WEB_WEBSPACE_EDIT
            },
            url: '/storage',
            views: {
                'content@authenticated': {
                    template: '<template-webspace-storage webspace="$resolve.webspace"></template-webspace-storage>'
                }
            }
        }
    },
    {
        name: 'managed-servers.id.webspaces.id.ftp-ssh',
        state: {
            data: {
                isGranted: UiRights.WEB_OBJECT_LIST
            },
            resolve: {
                webspaceAccessUsers: (
                    webspace: Types.WebhostingApi.Webspace,
                    webhostingUserModel: WebhostingUserModelService
                ) => {
                    return webhostingUserModel.list(500, null, { field: 'UserAccessesWebspaceId', value: webspace.id })
                    .then((res) => res.data) as ng.IPromise<Types.WebhostingApi.WebspaceAccess[]>;
                },
                webspaceUsers: (
                    webspace: Types.WebhostingApi.Webspace,
                    webhostingUserModel: WebhostingUserModelService
                ) => {
                    return webhostingUserModel.list(null, null, {field: 'accountId', value: webspace.accountId})
                        .then((res) => res.data) as ng.IPromise<Types.WebhostingApi.FindWebspacesResult[]>;
                }
            },
            url: '/user-overview',
            views: {
                'content@authenticated': {
                    template: `<template-webspace-access-overview
                                    webspace="$resolve.webspace"
                                    webspace-users="$resolve.webspaceUsers"
                                    webspace-access-users="$resolve.webspaceAccessUsers"
                                ></template-webspace-access-overview>`
                }
            }
        }
    },
    {
        name: 'managed-servers.id.webspaces.id.ftp-ssh.new',
        state: {
            data: {
                isGranted: UiRights.WEB_OBJECT_LIST
            },
            url: '/new',
            views: {
                'content@authenticated': {
                    template: `<template-webspace-access-add-user-overview
                                    webspace="$resolve.webspace"
                                ></template-webspace-access-add-user-overview>`
                }
            }
        }
    },
    {
        name: 'managed-servers.id.webspaces.id.backups',
        state: {
            abstract: true,
            data: {
                isGranted: UiRights.BACKUP_BACKUP_LIST
            },
            resolve: {
                backups: (webspace, backupModel: BackupModelService) => [undefined, null, ''].indexOf(webspace) === -1
                    ? backupModel.list('webhosting', 'webspace', webspace.id)
                    : []
            },
            url: '/backups'
        }
    },
    {
        name: 'managed-servers.id.webspaces.id.cronjobs',
        state: {
            abstract: true,
            data: {
                isGranted: UiRights.WEB_OBJECT_LIST
            },
            url: '/cronjobs'
        }
    },
    {
        name: 'managed-servers.id.webspaces.id.delete',
        state: {
            data: {
                isGranted: UiRights.WEB_WEBSPACE_DELETE
            },
            url: '/delete',
            views: {
                'content@authenticated': {
                    template: '<template-webspace-delete webspace="$resolve.webspace"></template-webspace-delete>'
                }
            }
        }
    },
    {
        data: {
            isGranted: UiRights.WEB_WEBSPACE_RESTORE
        },
        name: 'managed-servers.id.webspaces.id.restore',
        state: {
            url: '/restore',
            views: {
                'content@authenticated': {
                    template: '<template-webspace-delete webspace="$resolve.webspace"></template-webspace-delete>'
                }
            }
        }
    },
    {
        name: 'managed-servers.id.webspaces.id.cronjobs.overview',
        state: {
            resolve: {
                extendedFilters: (filterCache: FilterCacheService) => {
                    return filterCache.resolveExtendedFilters('cronjobs');
                },
                overviewModel: (cronjobOverviewModel: CronjobOverviewModel) => cronjobOverviewModel,
                overviewUiSettings: (userSettingsManager: UserSettingsManagerService) => {
                    return userSettingsManager.getSettingsForOverview('WebspaceOverviewCtrl');
                },
                simpleFilter: (filterCache: FilterCacheService) => {
                    return filterCache.resolveSimpleFilter('cronjobs');
                }
            },
            url: '/overview',
            views: {
                'content@authenticated': {
                    template: `<template-cronjob-overview
                                    simple-filter="$resolve.simpleFilter"
                                    extended-filters="$resolve.extendedFilters"
                                    overview-ui-settings="$resolve.overviewUiSettings"
                                    webspace="$resolve.webspace"
                                    overview-model="$resolve.overviewModel"
                                ></template-cronjob-overview>`
                }
            }
        }
    },
    {
        name: 'managed-servers.id.webspaces.id.cronjobs.new',
        state: {
            data: {
                isGranted: UiRights.WEB_WEBSPACE_EDIT
            },
            resolve: {
                cronjob: getDefaultCronjob
            },
            url: '/new',
            views: {
                'content@authenticated': {
                    template: '<template-cronjob-new cronjob="$resolve.cronjob"></template-cronjob-new>'
                }
            }
        }
    },
    {
        name: 'managed-servers.id.webspaces.id.cronjobs.id',
        state: {
            abstract: true,
            resolve: {
                cronjob: ($stateParams: ng.ui.IStateParamsService, webspace: any) => {
                    return webspace.cronJobs[$stateParams.cronjobId];
                },
                cronjobId: ($stateParams: ng.ui.IStateParamsService) => $stateParams.cronjobId
            },
            url: '/id/{cronjobId}'
        }
    },
    {
        name: 'managed-servers.id.webspaces.id.cronjobs.id.edit',
        state: {
            url: '/edit',
            views: {
                'content@authenticated': {
                    template: `<template-cronjob-edit
                                    cronjob="$resolve.cronjob"
                                ></template-cronjob-edit>`
                }
            }
        }
    },
    {
        name: 'managed-servers.id.webspaces.id.backups.overview',
        state: {
            url: '/overview',
            views: {
                'content@authenticated': {
                    // tslint:disable-next-line: max-line-length
                    template: '<template-webspace-backups backups="$resolve.backups" webspace="$resolve.webspace"></template-webspace-backups>'
                }
            }
        }
    },
    {
        name: 'managed-servers.id.databases',
        state: {
            abstract: true,
            url: '/databases'
        }
    },
    {
        name: 'managed-servers.id.databases.overview',
        state: {
            data: {
                isGranted: UiRights.DB_DATABASE_LIST
            },
            resolve: {
                extendedFilters: (filterCache: FilterCacheService) => {
                    return filterCache.resolveExtendedFilters('Databases');
                },
                overviewModel: (databaseOverviewModel: DatabaseOverviewModel) => databaseOverviewModel,
                overviewUiSettings: (userSettingsManager: UserSettingsManagerService) => {
                    return userSettingsManager.getSettingsForOverview('DatabaseOverviewCtrl');
                },
                simpleFilter: (filterCache: FilterCacheService) => {
                    return filterCache.resolveSimpleFilter('Databases');
                }
            },
            url: '/overview/',
            views: {
                'content@authenticated': {
                    template: `<template-database-overview
                        simple-filter="$resolve.simpleFilter"
                        extended-filters="$resolve.extendedFilters"
                        overview-ui-settings="$resolve.overviewUiSettings"
                        overview-model="$resolve.overviewModel"
                    ></template-database-overview>`
                }
            }
        }
    },
    {
        name: 'managed-servers.id.databases.new',
        state: {
            data: {
                isGranted: UiRights.DB_DATABASE_CREATE
            },
            url: '/new',
            views: {
                'content@authenticated': {
                    template: '<template-database-wizard></template-database-wizard>'
                }
            }
        }
    },
    {
        name: 'managed-servers.id.databases.id',
        state: {
            abstract: true,
            resolve: {
                backups: (backupModel: BackupModelService, database) => {
                    return backupModel.list('database', 'database', database.id);
                },
                database: (
                    $stateParams: ng.ui.IStateParamsService,
                    databaseModel: DatabaseModelService,
                    redirectOnNotFound: RedirectOnNotFoundCallback
                ) => {
                    return databaseModel.findOneById($stateParams.databaseId)
                        .then(stateUtilityFunctions.check404('Database not found'))
                        .then(null, redirectOnNotFound);
                },
                databaseUsers: (database, databaseUserModel: DatabaseUserModelService) => {
                    return databaseUserModel.list(100, null, {field: 'accountId', value: database.accountId})
                    .then(stateUtilityFunctions.check404('Database not found'))
                    .then((response) => {
                        return response.data;
                    });
                }
            },
            url: '/id/{databaseId}'
        }
    },
    {
        name: 'managed-servers.id.databases.id.edit',
        state: {
            data: {
                isGranted: UiRights.DB_DATABASE_EDIT
            },
            resolve: {
                showObjectId: (userSettingsManager: UserSettingsManagerService) => {
                    return userSettingsManager.isShowId();
                }
            },
            url: '/edit',
            views: {
                'content@authenticated': {
                    template: `<template-database-edit
                        database="$resolve.database"
                        database-users="$resolve.databaseUsers"
                        backups="$resolve.backups"
                        show-object-id="$resolve.showObjectId"
                    ></template-database-edit>`
                }
            }
        }
    },
    {
        name: 'managed-servers.id.databases.id.backups',
        state: {
            abstract: true,
            data: {
                isGranted: UiRights.BACKUP_BACKUP_LIST
            },
            resolve: {
                backups:
                    (database, backupModel: BackupModelService) => [undefined, null, ''].indexOf(database) === -1
                        ? backupModel.list('database', 'database', database.id)
                        : []
            },
            url: '/backups'
        }
    },
    {
        name: 'managed-servers.id.databases.id.backups.overview',
        state: {
            url: '/overview',
            views: {
                'content@authenticated': {
                    // tslint:disable-next-line: max-line-length
                    template: '<template-database-backups backups="$resolve.backups" database="$resolve.database"></template-database-backups>'
                }
            }
        }
    },
    {
        name: 'managed-servers.id.databases.id.restrictions',
        state: {
            data: {
                isGranted: UiRights.DB_DATABASE_EDIT
            },
            resolve: {
                restrictionHelper: (
                    database,
                    restrictionHelper: RestrictionHelperService,
                    databaseModel: DatabaseModelService
                ) => {
                    restrictionHelper.init(database, 'database', databaseModel, 'databaseModifyRestrictions');
                    return restrictionHelper;
                },
                showObjectId: (userSettingsManager: UserSettingsManagerService) => {
                    return userSettingsManager.isShowId();
                }
            },
            url: '/restrictions',
            views: {
                'content@authenticated': {
                    template: `<template-restrictions-edit
                        api-object="$resolve.database"
                        restriction-helper="$resolve.restrictionHelper"
                        show-object-id="$resolve.showObjectId"
                    ></template-restrictions-edit>`
                }
            }
        }
    }
];
