import { UiRights } from '@/configuration';
import { AuthContextService } from '@/services';
import { Breadcrumb, BreadcrumbItem } from '../breadcrumb';
import { BreadcrumbTopicModel, TopicCases } from './topic-model';

export class BreadcrumbMachineTopicModel extends BreadcrumbTopicModel {
    public static $inject: string[] = BreadcrumbTopicModel.$inject.concat([]);

    public readonly topicName: string = 'machine';
    public readonly specialTopicCases: TopicCases;

    /**
     *   List of route parts, which are excluded from mail class generalRoutes methode
     */
    public readonly excludeGeneralRouteParts = ['overview', 'new', 'edit'];

    private machineActionDropdownItems: BreadcrumbItem[] = [];

    constructor(protected $state: ng.ui.IStateService, protected $translate: ng.translate.ITranslateService) {
        super($state, $translate);

        this.machineActionDropdownItems = [
            { part: 'overview', route: 'machine.virtualmachines.overview', isGranted: UiRights.MACHINE_VM_LIST },
            { part: 'jobs', route: 'machine.virtualmachines.jobs', isGranted: UiRights.MACHINE_JOBS_LIST },
            { part: 'new', route: 'machine.virtualmachines.new', isGranted: UiRights.MACHINE_VM_CREATE },
            {
                isGrantedAll: [UiRights.RES_POOL_LIST, UiRights.ADMIN_SYSTEM_SUPER_USER_READ],
                part: 'pools',
                route: 'machine.pools',
                translationId: 'TR_140119-1516a5_TR'
            }
        ];

        this.specialTopicCases = {
            details: this.jobDetailsRoute,
            edit: this.getMachineEditRoutes,
            interfaces: this.getMachineInterfacesRoutes,
            jobs: () => this.getProductActionDropdown(this.machineActionDropdownItems),
            new: () => this.getProductActionDropdown(this.machineActionDropdownItems),
            'orderId': this.getOrderIdRoute,
            overview: () => this.getProductActionDropdown(this.machineActionDropdownItems),
            pools: () => this.getProductActionDropdown(this.machineActionDropdownItems)
        };
    }

    public getFirstLevelData = (): Breadcrumb[] => {
        this.breadcrumbs.push({
            active: false,
            type: 'text',
            value: this.$translate.instant('MENU.MAIN.PRODUCTS.LABEL')
        });

        if (AuthContextService.isGranted(UiRights.MACHINE_VM_LIST)) {
            this.breadcrumbs = this.breadcrumbs.concat(
                this.getServiceListDropdownBox({
                    active: this.steps.length === 2,
                    isGrantedAny: [UiRights.MACHINE_VM_LIST, UiRights.MACHINE_PM_LIST],
                    name: this.$translate.instant('9fd32096-6a1f-43c6-a467-4ef75d7593e7'),
                    route: 'machine.dashboard',
                    routeParams: {},
                    step: 'machine',
                    title: this.$translate.instant('TR_080119-276b31_TR')
                })
            );
        }

        return this.breadcrumbs;
    };

    public getMachineEditRoutes = (): Breadcrumb[] => {
        let breadcrumbs: Breadcrumb[] = [];
        let route = '';
        let routeParams = {};

        if (this.steps[this.index - 2] === 'virtualmachines' && [undefined].indexOf(this.globals.machine) < 0) {
            breadcrumbs = this.getProductActionDropdown(this.machineActionDropdownItems, {}, 'overview');

            route = 'machine.virtualmachines.id.edit';
            routeParams = { machineId: this.globals.machine.id };
            breadcrumbs.push({
                active: false,
                isGranted: UiRights.MACHINE_VM_EDIT,
                route: route,
                routeParams: routeParams,
                type: 'text',
                value: this.getActiveDropdownValue({ part: 'edit' })
            });
        }
        return breadcrumbs;
    };

    public getMachineInterfacesRoutes = (): Breadcrumb[] => [
        {
            active: true,
            isGranted: UiRights.MACHINE_VM_EDIT,
            route: 'machine.virtualmachines.id.edit.interfaces',
            routeParams: { machineId: this.globals.machine.id },
            type: 'text',
            value: this.$translate.instant('TR_110119-0a9347_TR')
        }
    ];

    public getOrderIdRoute = (): Breadcrumb[] => {
        return [
            {
                active: true,
                route: 'machine.setup',
                routeParams: { orderId: this.globals.orderId },
                type: 'text',
                value: 'Setup',
            }
        ];
    }
}
