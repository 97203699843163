import * as Types from '@/types';
import {
    GenericSetup,
    OrderItemDisplayLine,
    OrderItemStatus,
    GenericInjectedRequests,
    MaybeLoading,
} from '@/components/setup/setup';

type OrchestrationJob = Types.ViewTypes.OrchestrationJob;
type ManagedVirtualMachine = Types.MachineApi.VirtualMachine;
type APISingleResponse<T> = Types.UI.APISingleResponse<T>;

export type MachineManagedAdditionalInjectedRequests = {
    loadManagedVM: (id: string) => Promise<ManagedVirtualMachine>,
    updateManagedVM: (managedVM: ManagedVirtualMachine) => Promise<APISingleResponse<ManagedVirtualMachine>>,
}

export type InjectedRequests = GenericInjectedRequests & MachineManagedAdditionalInjectedRequests;

export class MachineManagedSetup extends GenericSetup {

    // fetched async from API
    private item: MaybeLoading<ManagedVirtualMachine> = {state: 'waiting'};

    constructor(
        protected requests: InjectedRequests,
        private applyWrapper?: (fn: () => void) => void,
    ) {
        super();
        applyWrapper ??= (fn: () => void) => fn();
    }

    protected jobIsDone = (job: OrchestrationJob) => {

        switch (job.objectType) {
            case 'ManagedVM':
                if (this.item.state == 'waiting') {
                    this.item = {state: 'loading'};
                    this.requests.loadManagedVM(job.objectId).then(
                        (managedVM: ManagedVirtualMachine) => {
                            this.item = { state: 'loaded', data: managedVM };
                        },
                    );
                }
                break;
        }

    }

    protected postprocessOrderItemDisplayLines = () => {}

    protected orderItemDisplayLinesBeforeJobIsLoaded = (): OrderItemDisplayLine[] => {
        // Loading data, to avoid jumping element positions
        const lines: OrderItemDisplayLine[] = [];
        lines.push(new OrderItemDisplayLine('ManagedVirtualMachine', 'ManagedVirtualMachine', OrderItemStatus.PENDING));
        return lines;
    }
}
