export const sleep = (ms: number): Promise<void> => {
    return new Promise(resolve => setTimeout(resolve, ms));
};

export const makeStableReturn = <T,>(fn: () => T): () => T => {
    const storage: {data?: T} = {}
    return () => {
        const realReturn = fn();

        if (storage.data === undefined || JSON.stringify(realReturn) != JSON.stringify(storage.data)) {
            storage.data = realReturn;
        }
        return storage.data;
    }
}

export const sortByRank = <T extends {objectType: string}>(input: T[], ranking: string[]): void => {
    const stringToRank = (item: T): number => {
        const tmp = ranking.indexOf(item.objectType);
        // If not in ranking place towords the of the sorted result
        if (tmp === -1) {
            return 1000;
        }
        return tmp;
    };
    input.sort((a, b) => stringToRank(a) - stringToRank(b));
};
