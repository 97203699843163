import ng from 'angular';
import { UiRights } from '../../../../configuration';
import { AuthContextService } from '../../../../services';
import { Breadcrumb, BreadcrumbItem } from '../breadcrumb';
import { BreadcrumbTopicModel, TopicCases } from './topic-model';

export class BreadcrumbDomainTopicModel extends BreadcrumbTopicModel {
    public static $inject: string[] = BreadcrumbTopicModel.$inject.concat([]);

    public readonly topicName = 'domain';
    public readonly specialTopicCases: TopicCases;

    /**
     *   List of route parts, which are excluded from mail class generalRoutes methode
     */
    public readonly excludeGeneralRouteParts = ['overview', 'edit', 'new'];

    private domainActionDropdownItems: BreadcrumbItem[] = [];

    private contactActionDropdownItems = [
        {
            isGranted: UiRights.DOM_CONTACTS_LIST,
            part: 'overview',
            route: 'domain.contacts.overview'
        },
        {
            isGranted: UiRights.DOM_CONTACTS_LIST,
            name: this.$translate.instant('MENU.CONTEXT.DOMAIN.CONTACT-VERIFICATION'),
            part: 'verification',
            route: 'domain.contacts.verification'
        }
    ];

    constructor(protected $state: ng.ui.IStateService, protected $translate: ng.translate.ITranslateService) {
        super($state, $translate);

        this.domainActionDropdownItems = [
            {
                isGranted: UiRights.DOM_DOMAINS_LIST,
                part: 'overview',
                route: 'domain.domains.overview'
            },
            {
                isGranted: UiRights.DOM_JOBS_LIST,
                part: 'jobs',
                route: 'domain.domains.jobs'
            },
            {
                isGranted: UiRights.DOM_DOMAINS_CREATE_WITH_DEFAULTS,
                part: 'new',
                route: 'domain.domains.new'
            },
            {
                isGranted: UiRights.DOM_CONTACTS_LIST,
                name: this.$translate.instant('e0899970-4e96-4cce-92ca-a3fef30ecc35'),
                part: 'contacts',
                route: 'domain.contacts.overview'
            },
            {
                isGranted: UiRights.WEB_REDIRECTIONS_LIST,
                name: this.$translate.instant('MENU.CONTEXT.DOMAIN.REDIRECTIONS'),
                part: 'redirections',
                route: 'domain.redirections.overview'
            },
            {
                isGranted: UiRights.DOM_CONTACTS_LIST,
                name: this.$translate.instant('GENERAL.JOB.ACTION.CONTACTOWNERVERIFICATION'),
                route: 'domain.contacts.verification',
                part: 'contactownerverification'
            },
            {
                isGranted: UiRights.DOM_DOMAINS_LIST,
                name: this.$translate.instant('MENU.CONTEXT.DOMAIN.AVAIL-CHECK'),
                part: 'availability-check',
                route: 'domain.availability-check'
            },
            {
                isGranted: UiRights.DOM_JOBS_LIST,
                name: this.$translate.instant('MENU.CONTEXT.DOMAIN.TRANSFER-OUT'),
                part: 'transfers',
                route: 'domain.transfers'
            },
            {
                isGranted: UiRights.DOM_DOMAINS_LIST,
                name: this.$translate.instant('MENU.CONTEXT.DOMAIN.REPORTS'),
                part: 'reports',
                route: 'domain.reports'
            },
            {
                isGranted: UiRights.DOM_DOMAINS_AUTHINFO_CREATE,
                name: this.$translate.instant('TR_080119-a3f334_TR'),
                part: 'authinfotwo',
                route: 'domain.authinfotwo'
            }
        ];

        this.specialTopicCases = {
            'authinfotwo': () => this.getProductActionDropdown(this.domainActionDropdownItems, $state.params),
            'availability-check': () => this.getProductActionDropdown(this.domainActionDropdownItems, $state.params),
            'contacts': () => this.getProductActionDropdown(this.domainActionDropdownItems, $state.params),
            'details': this.jobDetailsRoute,
            'edit': this.getEditRoute,
            'jobs': () => this.getProductActionDropdown(this.domainActionDropdownItems, $state.params),
            'move': this.getMoveRoute,
            'new': () => this.getProductActionDropdown(this.domainActionDropdownItems, $state.params),
            'overview': this.getOverviewsRoute,
            'records': this.getZoneRoute,
            'redirections': () => this.getProductActionDropdown(this.domainActionDropdownItems, $state.params),
            'reports': () => this.getProductActionDropdown(this.domainActionDropdownItems, $state.params),
            'transfers': () => this.getProductActionDropdown(this.domainActionDropdownItems, $state.params),
            'contactownerverification': () =>
                this.getProductActionDropdown(this.domainActionDropdownItems, $state.params),
            'verification': () => this.getProductActionDropdown(this.contactActionDropdownItems, $state.params),
            'orderId': this.getOrderIdRoute,
        };
    }

    public getFirstLevelData = (): Breadcrumb[] => {
        this.breadcrumbs.push({
            active: false,
            type: 'text',
            value: this.$translate.instant('MENU.MAIN.PRODUCTS.LABEL')
        });

        if (AuthContextService.isGranted(UiRights.DOM_DOMAINS_LIST)) {
            this.breadcrumbs = this.breadcrumbs.concat(
                this.getServiceListDropdownBox({
                    active: this.steps.length === 2,
                    isGranted: UiRights.DOM_DOMAINS_LIST,
                    name: this.$translate.instant('TR_140119-aeb48a_TR'),
                    route: 'domain.dashboard',
                    routeParams: {},
                    step: 'domain'
                })
            );
        }

        return this.breadcrumbs;
    };

    public getOverviewsRoute = (): Breadcrumb[] => {
        switch (this.steps[this.index - 1]) {
            case 'domains':
                return this.getProductActionDropdown(this.domainActionDropdownItems);
            case 'contacts':
                return this.getProductActionDropdown(this.contactActionDropdownItems);
            default:
                break;
        }
    };

    public getEditRoute = (): Breadcrumb[] => {
        let breadcrumbs: Breadcrumb[] = [];
        let route = '';
        let routeParams = {};

        if (this.steps[this.index - 2] === 'domains') {
            route = 'domain.domains.id.edit';
            routeParams = { domainId: this.globals.domain.id };
            breadcrumbs = this.getProductActionDropdown(this.domainActionDropdownItems, routeParams, 'overview');
        }

        breadcrumbs.push({
            active: true,
            isGranted: UiRights.DOM_DOMAINS_EDIT,
            route: route,
            routeParams: routeParams,
            type: 'text',
            value: this.getActiveDropdownValue({ part: 'edit' })
        });

        return breadcrumbs;
    };

    public getZoneRoute = (step?: string): Breadcrumb[] => {
        let breadcrumbs: Breadcrumb[] = [];
        const routeParams = {};

        if (this.steps[this.index - 2] === 'domains') {
            breadcrumbs = this.getProductActionDropdown(this.domainActionDropdownItems, routeParams, 'overview');
        }

        if (this.steps[this.index - 1] === 'id') {
            breadcrumbs.push({
                active: false,
                isGranted: UiRights.DOM_DOMAINS_EDIT,
                route: 'domain.domains.id.edit',
                routeParams: { domainId: this.globals.domain.id },
                type: 'text',
                value: this.globals.domain.name
            });
        }

        breadcrumbs.push({
            active: step === 'records',
            isGranted: UiRights.DOM_DOMAINS_EDIT,
            route: 'domain.domains.id.records',
            routeParams: { domainId: this.globals.domain.id },
            type: 'text',
            value: this.$translate.instant('TR_080119-739c17_TR')
        });

        return breadcrumbs;
    };

    public getContactServiceRoute = (step?: string): Breadcrumb[] => {
        step = step || this.step;

        return [
            {
                active: step === 'contacts',
                disabled: false,
                isGranted: UiRights.DOM_CONTACTS_LIST,
                route: 'domain.contacts.overview',
                routeParams: {},
                title: this.$translate.instant('TR_080119-69f536_TR'),
                type: 'text',
                value: this.$translate.instant('e0899970-4e96-4cce-92ca-a3fef30ecc35')
            }
        ];
    };

    public getMoveRoute = (): Breadcrumb[] => [
        {
            active: true,
            type: 'text',
            value: this.$translate.instant('83f251a8-7d02-4f5d-a45e-90fc3502ad67')
        }
    ];

    public getOrderIdRoute = (): Breadcrumb[] => {
        return [
            {
                active: true,
                route: 'domain.setup',
                routeParams: { orderId: this.globals.orderId },
                type: 'text',
                value: 'Setup',
            }
        ];
    }
}
