import * as Sentry from '@sentry/browser';

type Primitive = string | boolean | number;
type PrimitiveDict = {[key: string]: Primitive }
type Dict = PrimitiveDict | {[key: string]: PrimitiveDict | Dict};

export class SentryErrorEmitterService {
    public static $inject: string[] = [];
    public static messageTypes = Sentry.Severity;

    constructor() {}

    public static sendSentryReport(
        capture: Error | string,
        extra: Dict = {},
        tags: PrimitiveDict = {},
        messageType: Sentry.Severity = Sentry.Severity.Error,
        log: boolean = true,
    ) {
        switch (true) {
            case typeof capture === 'string' && capture === 'API pricelist error':
            case capture instanceof Error && capture.message === 'API pricelist error':
                if (!this.sendApiPriceListError((extra as any).productCode)) return;
            case capture instanceof Error:
                this.logToConsole(capture, extra, tags, messageType, log);
                this.sendSentryError(capture as Error, extra, tags);
                break;
            default:
                this.logToConsole(capture, extra, tags, messageType, log);
                this.sendSentryMessage(capture as string, extra, tags, messageType);
        }
    }

    private static sendSentryError(
        error: Error,
        extra: Dict,
        tags: PrimitiveDict,
    ): void {
        Sentry.captureException(
            error,
            {
                extra,
                tags,
            },
        );
    }

    private static sendSentryMessage(
        captureMessage: string,
        extra: Dict,
        tags: PrimitiveDict,
        messageType: Sentry.Severity = Sentry.Severity.Error,
    ): void {
        Sentry.captureMessage(
            captureMessage,
            {
                level: messageType,
                extra,
                tags,
            },
        );
    }

    private static logToConsole(
        capture: Error | string,
        extra: any,
        tags: PrimitiveDict,
        messageType: Sentry.Severity,
        log: boolean,
    ) {
        if (!log) return;
        switch (true) {
            case capture instanceof Error:
            case messageType === Sentry.Severity.Critical:
            case messageType === Sentry.Severity.Error:
            case messageType === Sentry.Severity.Fatal:
                console.error(capture, extra, tags);
                break;
            case messageType === Sentry.Severity.Warning:
                console.warn(capture, extra, tags);
                break;
            case messageType === Sentry.Severity.Debug:
            case messageType === Sentry.Severity.Info:
            case messageType === Sentry.Severity.Log:
            default:
                console.log(capture, extra, tags);
        }
    }

    private static sendApiPriceListError(productCode: string): boolean {
        if ([
            'machine-disk-small-v2',
            'machine-disk-medium-v2',
            'machine-disk-large-v2',
            'machine-disk-xlarge-v2',
            'machine-disk-xxlarge-v2',
            'machine-disk-small-v1',
            'machine-disk-medium-v1',
            'machine-disk-large-v1',
            'machine-disk-xlarge-v1',
            'machine-disk-xxlarge-v1',
            'machine-disk-ecommerce-medium-v1',
            'machine-disk-ecommerce-large-v1',
            'machine-disk-ecommerce-xlarge-v1',
            'machine-disk-ecommerce-xxlarge-v1',
        ].includes(productCode)) return false;
        return true;
    }
}
