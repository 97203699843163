import ng, { IScope } from 'angular';
import * as Types from '@/types';
import { TemplateSetupController } from '@/components/setup';
import {
    AccountModelService,
    DepositModelService,
    OrchestratorRobotService,
    StorageModelService,
    NavigationService,
} from '@/services';
import {
    StorageSetup,
    StorageAdditionalInjectedRequests,
} from './storage-setup';
import { applyIfNeeded } from '@/services/helpers/angular-helpers';

type APISingleResponse<T> = Types.UI.APISingleResponse<T>;
type Nextcloud = Types.ManagedApplicationApi.Nextcloud;

export class TemplateStorageSetupController extends TemplateSetupController {
    public static $inject: string[] = [
        'accountModel',
        'depositModel',
        'orchestratorRobot',
        '$rootScope',
        '$translate',
        'storageModel',
        'navigation',
    ];
    public storageSetup: StorageSetup;
    constructor(
        accountModel: AccountModelService,
        depositModel: DepositModelService,
        orchestratorRobot: OrchestratorRobotService,
        $rootScope: IScope,
        $translate: ng.translate.ITranslateService,
        private storageModel: StorageModelService,
        private navigation: NavigationService,
    ){
        super(
            accountModel,
            depositModel,
            orchestratorRobot,
            $rootScope,
            $translate,
        );
    }

    public $onInit(): void {
        this.pageHeaderData = {
            backwardLink: 'nextcloud.overview',
            backwardText: this.$translate.instant('TR_010819-62941c_TR'),
            panelHeaderRoute: '',
            panelHeaderRouteParams: {},
            panelIcon: 'box-alt',
            panelTitle: this.$translate.instant('18249206-2af0-4e97-abe6-51184efa0ce2'),
        };
        const additionalInjectionRequests: StorageAdditionalInjectedRequests = {
            loadNextcloud: (id: string): Promise<Nextcloud> => {
                return this.storageModel.findOne(id);
            },
            updateNextcloud: (storage: Nextcloud): Promise<APISingleResponse<Nextcloud>> => {
                return this.storageModel.nextcloudUpdate(storage);
            },
        }
        this.storageSetup = new StorageSetup(
            {...this.genericInjectionRequests, ...additionalInjectionRequests},
            (fn: () => void) => {
                applyIfNeeded(this.$rootScope, fn);
            });
        super.$onInitGeneric(this.storageSetup);
    }

    public goToNextcloudDashboard = () => {
        this.navigation.go('nextcloud.overview');
    };
}

export class TemplateStorageSetupComponent implements ng.IComponentOptions {
    public bindings = {
        orderId: '<',
    };
    public template = require('./storage-setup-template.html');
    public controller = TemplateStorageSetupController;
}
