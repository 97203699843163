import * as Types from '@/types';
import { makeStableReturn, sortByRank } from '@/services/helpers/helpers-without-framework';
import { DateWrapper, SentryErrorEmitterService } from '@/services';

type APISingleResponse<T> = Types.UI.APISingleResponse<T>;
type BankAccount = Types.BillingApi.BankAccount;
type OrchestrationJob = Types.ViewTypes.OrchestrationJob;
type PaymentMethod = Types.BillingApi.PaymentMethod;

export type GenericInjectedRequests = {
    createBankAccount: (iban: string, accountHolder: string, bic: string, dateOfBirth: Date) => Promise<APISingleResponse<BankAccount>>,
    checkPaymentMethodsAvailability: () => Promise<PaymentMethod[]>,
    getBankAccount: () => Promise<BankAccount[]>,
}

export interface SepaData {
    iban: string,
    accountHolder: string,
    bic?: string,
    bankName?: string,
}

export enum OrderItemStatus {
    PENDING = 'PENDING',
    SUCCESSFUL = 'SUCCESSFUL',
    SUPPORT = 'SUPPORT',
    BLANK = 'BLANK',
    NODOMAIN = 'NODOMAIN',
}

export type Loading = {
    state: 'loading';
};

export type Waiting = {
    state: 'waiting';
};

export type Loaded<T> = {
    state: 'loaded';
    data: T;
};
export type MaybeLoading<T> = Loading | Waiting | Loaded<T>;


export class OrderItemDisplayLine {
    public readonly id: string;
    public readonly objectType: string;
    public readonly status: OrderItemStatus;

    constructor(
        id: string,
        objectType: string,
        status: OrderItemStatus,
    ) {
        this.id = id;
        this.objectType = objectType;
        this.status = status;
    }
}

export abstract class GenericSetup {

    public foundOrderId: 'loading' | 'found' | 'notfound' = 'loading';

    // fetched async from API
    public jobs?: OrchestrationJob[];
    public showSepaPanel = true;
    private bankAccount: Types.BillingApi.BankAccount;

    // running async processes
    public paymentMethodIsUpdating = false;

    protected abstract requests: GenericInjectedRequests;

    protected abstract jobIsDone: (job: OrchestrationJob) => void;

    public updateOrchestrationJobs(jobs: OrchestrationJob[]) {
        this.jobs = jobs;
        // Load objects, if status is successful
        if (this.foundOrderId === 'loading') {
            this.foundOrderId = this.jobs.length > 0 ? 'found' : 'notfound';
            if (this.foundOrderId === 'notfound') {
                SentryErrorEmitterService.sendSentryReport(
                    'Setup of given orderId failed',
                    null,
                    { component: 'Setup' },
                    SentryErrorEmitterService.messageTypes.Info,
                );
            }
        }

        for (const job of jobs) {
            if (job.status === 'successful' || job.status === 'done') {
                this.jobIsDone(job);
            }
        }
    }

    public loadInitialData = () => {
        this.checkSepaAllowedAsPaymentMethod();
        void this.getBankAccount();
    }

    protected abstract orderItemDisplayLinesBeforeJobIsLoaded: () => OrderItemDisplayLine[];
    protected abstract postprocessOrderItemDisplayLines: (lines: OrderItemDisplayLine[]) => void;

    orderItemDisplayLines = makeStableReturn(() => {
        if (!Array.isArray(this.jobs)) {
            return this.orderItemDisplayLinesBeforeJobIsLoaded();
        }
        const objectTypeOrder = ['Bundle', 'Webspace', 'Zone', 'VHost'];
        const jobs = [...this.jobs];
        sortByRank(jobs, objectTypeOrder);
        const lines: OrderItemDisplayLine[] = [];
        for (const job of jobs) {
            let status: OrderItemStatus;
            switch (job.status) {
                case 'done':
                case 'successful':
                    status = OrderItemStatus.SUCCESSFUL;
                    break;
                case 'failed':
                case 'support':
                    status = OrderItemStatus.SUPPORT;
                    break;
                default:
                    status = OrderItemStatus.PENDING;
            }
            lines.push(new OrderItemDisplayLine(job.objectType, job.objectType, status));
        }
        this.postprocessOrderItemDisplayLines(lines);
        return lines;
    });

    get orderCompleted(): boolean {
        if (this.jobs === undefined) {
            return false;
        }
        for (const job of this.jobs) {
            if (job.status !== 'successful') {
                return false;
            }
        }
        return true;
    }

    get showFoundOrSearchingPanel(): boolean {
        return this.foundOrderId !== 'notfound';
    }

    public saveSepaPaymentMethod: (
        sepaData: SepaData,
        usersDateOfBirth: DateWrapper
    ) => Promise<string> = async (
        sepaData: SepaData,
        usersDateOfBirth: DateWrapper=undefined
    ) => {
        // delete BIC information, if bic is not required
        if ((/^DE/i.test(sepaData.iban))) {
            delete sepaData.bic;
        }
        this.paymentMethodIsUpdating = true;
        const bic = sepaData.bic && sepaData.bic.length > 0 ? sepaData.bic : undefined;
        let dateOfBirth: Date;
        let apiResponse;

        if (usersDateOfBirth !== undefined) {
            dateOfBirth = usersDateOfBirth.dateObj;
        }

        apiResponse = await this.requests.createBankAccount(
            sepaData.iban,
            sepaData.accountHolder,
            bic,
            dateOfBirth
        );
        void this.getBankAccount();
        this.paymentMethodIsUpdating = false;
        return apiResponse.response.status;
    }

    public checkSepaAllowedAsPaymentMethod = (): void => {
        this.requests.checkPaymentMethodsAvailability().then(
            (paymentMethodAvailability: {paymentMethod: string; available: boolean}[]) => {
                let allowed = true;
                paymentMethodAvailability.forEach((element) => {if (element.paymentMethod === 'directDebit') allowed = element.available});
                this.showSepaPanel = allowed;
            }
        );
    }

    public getBankAccount = () => {
        return this.requests.getBankAccount().then(
            (response: BankAccount[]) => {
                if (response.length === 0) {
                    return;
                }
                this.bankAccount = response[0];
            }
        );
    }

    get sepaStatus(): string {
        if (this.bankAccount === undefined)
            return '';
        return this.bankAccount.status;
    }

}
