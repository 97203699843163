import * as Types from '@/types';
import {
    GenericSetup,
    OrderItemDisplayLine,
    OrderItemStatus,
    GenericInjectedRequests,
    MaybeLoading,
} from '@/components/setup/setup';

type OrchestrationJob = Types.ViewTypes.OrchestrationJob;
type Storage = Types.ManagedApplicationApi.Nextcloud;
type APISingleResponse<T> = Types.UI.APISingleResponse<T>;

export type StorageAdditionalInjectedRequests = {
    loadNextcloud: (id: string) => Promise<Storage>,
    updateNextcloud: (storage: Storage) => Promise<APISingleResponse<Storage>>,
}

export type InjectedRequests = GenericInjectedRequests & StorageAdditionalInjectedRequests;

export class StorageSetup extends GenericSetup {

    // fetched async from API
    private item: MaybeLoading<Storage> = {state: 'waiting'};

    constructor(
        protected requests: InjectedRequests,
        private applyWrapper?: (fn: () => void) => void,
    ) {
        super();
        applyWrapper ??= (fn: () => void) => fn();
        // Suppress sentry message until we find a way to deal with nextcloud id
        this.foundOrderId = 'found'
    }

    protected jobIsDone = (job: OrchestrationJob) => {
        switch (job.objectType) {
            case 'Storage':
                if (this.item.state == 'waiting') {
                    this.item = {state: 'loading'};
                    this.requests.loadNextcloud(job.objectId).then(
                        (storage: Storage) => {
                            this.item = { state: 'loaded', data: storage };
                        },
                    );
                }
                break;
        }

    }

    protected postprocessOrderItemDisplayLines = () => {}

    protected orderItemDisplayLinesBeforeJobIsLoaded = (): OrderItemDisplayLine[] => {
        // Loading data, to avoid jumping element positions
        const lines: OrderItemDisplayLine[] = [];
        lines.push(new OrderItemDisplayLine('Nextcloud', 'Storage', OrderItemStatus.PENDING));
        return lines;
    }
}
