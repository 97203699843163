import * as Types from '@/types';
import {
    GenericSetup,
    OrderItemDisplayLine,
    OrderItemStatus,
    GenericInjectedRequests,
    MaybeLoading,
} from '@/components/setup/setup';

type OrchestrationJob = Types.ViewTypes.OrchestrationJob;
type Webspace = Types.WebhostingApi.Webspace;
type APISingleResponse<T> = Types.UI.APISingleResponse<T>;

export type WebhostingAdditionalInjectedRequests = {
    loadWebspace: (id: string) => Promise<Webspace>,
    updateWebspace: (webspace: Webspace) => Promise<APISingleResponse<Webspace>>,
}

export type InjectedRequests = GenericInjectedRequests & WebhostingAdditionalInjectedRequests;

export class WebhostingSetup extends GenericSetup {

    // fetched async from API
    private item: MaybeLoading<Webspace> = {state: 'waiting'};

    constructor(
        protected requests: InjectedRequests,
        private applyWrapper?: (fn: () => void) => void,
    ) {
        super();
        applyWrapper ??= (fn: () => void) => fn();
    }

    protected jobIsDone = (job: OrchestrationJob) => {
        switch (job.objectType) {
            case 'Webspace':
                if (this.item.state == 'waiting') {
                    this.item = {state: 'loading'};
                    this.requests.loadWebspace(job.objectId).then(
                        (webspace: Webspace) => {
                            this.item = { state: 'loaded', data: webspace };
                        },
                    );
                }
                break;
        }

    }

    protected postprocessOrderItemDisplayLines = (lines: OrderItemDisplayLine[]) => {
        // This triggers when the bundle was ordered without a domain name.
        if (lines.length <= 1) {
            lines.push(new OrderItemDisplayLine('dummy1', '\u00A0', OrderItemStatus.BLANK));
            lines.push(new OrderItemDisplayLine('dummy2', '\u00A0', OrderItemStatus.NODOMAIN));
            lines.push(new OrderItemDisplayLine('dummy3', '\u00A0', OrderItemStatus.BLANK));
        }
    }

    protected orderItemDisplayLinesBeforeJobIsLoaded = (): OrderItemDisplayLine[] => {
        // Loading data, to avoid jumping element positions
        const lines: OrderItemDisplayLine[] = [];
        lines.push(new OrderItemDisplayLine('Webspace', 'Webspace', OrderItemStatus.PENDING));
        lines.push(new OrderItemDisplayLine('dummy1', '\u00A0', OrderItemStatus.BLANK));
        lines.push(new OrderItemDisplayLine('dummy2', '\u00A0', OrderItemStatus.BLANK));
        lines.push(new OrderItemDisplayLine('dummy3', '\u00A0', OrderItemStatus.BLANK));
        return lines;
    }
}
