import { IScope } from 'angular';

export const applyIfNeeded = ($rootScope: IScope, fn: () => void): void => {
    if ($rootScope.$$phase) {
        fn();
    } else {
        $rootScope.$applyAsync();
        fn();
    }
};
